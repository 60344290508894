import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHomeJsonList,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";
import CreatableSelect from "react-select/creatable";
import ImageCropper from "../ImageCropper/ImageCropper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

const EditSectionThree = () => {
  const [checklist, setChecklist] = useState<any>([{ id: 1, title: "" }]);
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);

  const images: Array<string> = [
    "assets/img/trustimg.png",
    "assets/img/easy-to-img.png",
    "assets/img/trustimg.png",
    "assets/img/easy-to-img.png",
    "assets/img/trustimg.png",
    "assets/img/easy-to-img.png",
    "assets/img/trustimg.png",
    "assets/img/easy-to-img.png",
  ];

  const saveJsonData = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  const handleHeaderChange = (e: any) => {
    const { value, name } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      home: {
        ...prevJsonData.home,
        section_3: {
          ...prevJsonData.home.section_3,
          [name]: value,
          btn_group: {
            ...prevJsonData.home.section_3.btn_group,
            btn1: {
              ...prevJsonData.home.section_3.btn_group.btn1,
              [name]: value,
            },
          },
        },
      },
    }));
  };

  const handleImageChange = (image: string) => {
    //Update the banner value in the JSON data
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      home: {
        ...prevJsonData.home,
        section_3: {
          ...prevJsonData.home.section_3,
          banner: image,
        },
      },
    }));
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    console.log("image", imageLink);
    if (imageLink != false) {
      handleImageChange(imageLink);
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    setCropImageHeight("610");
    setCropImageWidth("675");
    setSelectedCropImage(image);
    setShowCropModal(true);
  };

  useEffect(() => {
    const checklistData = editData?.home?.section_3?.check_list;
    if (checklistData) {
      setChecklist(checklistData);
    }
  }, [editData]);

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  return (
    <>
      {/* <Draggable> */}
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Three Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Title</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="section_3.title"
                        value={editData?.home?.section_3?.title}
                        onChange={handleHeaderChange}
                        maxLength={1200}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Paragraph</label>
                      <input
                        type="text"
                        className="form-control"
                        name="paragraph"
                        value={editData?.home?.section_3?.paragraph}
                        onChange={handleHeaderChange}
                        maxLength={1200}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Sub Heading</label>
                      <input
                        type="text"
                        className="form-control"
                        name="sub_heading"
                        value={editData?.home?.section_3?.sub_heading}
                        onChange={handleHeaderChange}
                        maxLength={1200}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Upload Image</label>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        className="form-control"
                        name="image"
                        onChange={updateImageSectionOne}
                      />
                    </div>
                    <div className="edit-section">
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="img-bg active">
                                  <img
                                    src={editData?.home?.section_3?.banner}
                                    alt="Card Icon"
                                  />
                                </div>
                              </div>
                              <div className="img-options">
                                <div className="row">
                                  <div className="col-md-12">
                                    <Swiper
                                      spaceBetween={15}
                                      navigation={true}
                                      modules={[Navigation]}
                                      breakpoints={{
                                        640: {
                                          slidesPerView: 1,
                                        },
                                        768: {
                                          slidesPerView: 4,
                                        },
                                        1024: {
                                          slidesPerView: 6,
                                        },
                                      }}
                                      className="homejson-slider"
                                    >
                                      {images.map((images: string) => {
                                        return (
                                          <>
                                            <SwiperSlide>
                                              <div
                                                className="img-option"
                                                onClick={() =>
                                                  handleImageChange(images)
                                                }
                                              >
                                                <img
                                                  src={images}
                                                  alt="Hero Image"
                                                  className="img-fluid"
                                                />
                                              </div>
                                            </SwiperSlide>
                                          </>
                                        );
                                      })}
                                    </Swiper>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label className="form-label">Check List</label>

                          <CreatableSelect
                            isMulti
                            name="checklist"
                            options={checklist}
                            // options={checklist.title}
                            value={checklist.map((item: any) => ({
                              label: item.title,
                              value: item.title,
                            }))}
                            onChange={(selectedOptions: any) => {
                              const updatedChecklist = selectedOptions.map(
                                (option: any) => ({
                                  id: Date.now(),
                                  title: option.value,
                                })
                              );
                              setChecklist(updatedChecklist);
                              setEditData((prevJsonData: any) => ({
                                ...prevJsonData,
                                home: {
                                  ...prevJsonData.home,
                                  section_3: {
                                    ...prevJsonData.home.section_3,
                                    check_list: updatedChecklist,
                                  },
                                },
                              }));
                            }}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Button</label>
                    <input
                      type="text"
                      className="form-control"
                      name="section_3btn1.text"
                      value={editData?.home?.section_3?.btn_group?.btn1?.text}
                      onChange={handleHeaderChange}
                    />
                  </div>
                </div>
                <div className="col">
                <div className=" mb-3">
                    <label className="form-label">Bg Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="bg_color"
                        value={
                          editData?.home?.section_3?.btn_group?.btn1?.bg_color
                        }
                      />
                      <ColorPicker
                        name="bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.section_3?.btn_group?.btn1?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                <div className=" mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="color"
                        value={
                          editData?.home?.section_3?.btn_group?.btn1?.color
                        }
                      />
                      <ColorPicker
                        name="color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.section_3?.btn_group?.btn1?.color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonData();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default EditSectionThree;
