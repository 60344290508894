import React from "react";
import {
  appdownload,
  appstore,
  cleancloths,
  googleplay,
  mobicon1,
  mobicon2,
  mobicon3,
  secimg1,secimg2,secimg3
} from "../../assets/img";
import { useSelector } from "react-redux";

const SectionFive = () => {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);

  // HTML
  return (
    <>
      <section className="mobapp-section">
        <div className="mobapp-subtract-top"></div>
        <div className="mobapp-subtract-body">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="mobapp-content text-center">
                  <h2 className="section-title">
                    {jsonData?.home?.section_5?.title}
                  </h2>
                  <p>{jsonData?.home?.section_5?.paragraph}</p>
                  <ul className="applinks">
                    <li>
                      <a
                        target="_blank"
                        href={
                          jsonData?.home?.section_5?.mobile_link_section
                            ?.ios_link
                        }
                      >
                        <img src={appstore} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href={
                          jsonData?.home?.section_5?.mobile_link_section
                            ?.android_link
                        }
                      >
                        <img src={googleplay} alt="" />
                      </a>
                    </li>
                  </ul>

                  <ul className="appdata">
                  {jsonData?.home?.section_5?.check_list.map(
              (items: any) => {
                return (
                  <li>
                    <img src={items.icons} alt="" />
                      <h4>{items.count}</h4>
                      <p>{items.title}</p>
                  </li>
                  );
                }
              )}

                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mobapp-img text-center">
                  <img src={appdownload} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobapp-subtract-bottom"></div>
      </section>
    </>
  );
};

export default SectionFive;
