import React from "react";
import {
  cleancloths,
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
} from "../../assets/img";

const SectionEight = () => {
  // HTML
  return (
    <>
      <section className="testimonial-section">
        <div className="testimonial-subtract-top"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="section-title">
                What Our <span>Client</span> Say{" "}
              </h2>
            </div>
            {/* <div className='col-md-6 text-end'>
                            <a className='btn primary-btn hover-btn'>View more</a>
                        </div> */}
          </div>
          <div className="row">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <div className="testimonial-card mb-3">
                  <div className="profile-user">
                    <a className="profile-user-img">
                      <img src={testimonialuser1} />
                    </a>
                    <div className="profile-user-info">
                      <h6>
                        <a>Judy Wells</a>
                      </h6>
                      <p>Chairman and founder</p>
                    </div>
                  </div>
                  <p>
                    My suits came back quickly and felt newer than the day I
                    bought them great that was easy to use,best part they came
                    to pick them up and drop them off for me.
                  </p>
                </div>
                <div className="testimonial-img">
                  <img src={testimonialimg1} alt="" />
                  <div className="testimonial-overlay">
                    <p>
                      My suits came back quickly and felt newer than the day I
                      bought them great that was easy to use,best part they came
                      to pick them up and drop them off for me.
                    </p>
                    {/* <a className='play-btn hover-btn'><i className="fas fa-caret-right"></i></a> */}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="testimonial-img">
                  <img src={testimonialimg2} alt="" />
                  <div className="testimonial-overlay">
                    <p>
                      My suits came back quickly and felt newer than the day I
                      bought them great that was easy to use,best part they came
                      to pick them up and drop them off for me.
                    </p>
                    {/* <a className='play-btn hover-btn'><i className="fas fa-caret-right"></i></a> */}
                  </div>
                </div>
                <div className="testimonial-card mt-3">
                  <div className="profile-user">
                    <a className="profile-user-img">
                      <img src={testimonialuser2} />
                    </a>
                    <div className="profile-user-info">
                      <h6>
                        <a>Judy Wells</a>
                      </h6>
                      <p>Chairman and founder</p>
                    </div>
                  </div>
                  <p>
                    My suits came back quickly and felt newer than the day I
                    bought them great that was easy to use,best part they came
                    to pick them up and drop them off for me.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="testimonial-card mb-3">
                  <div className="profile-user">
                    <a className="profile-user-img">
                      <img src={testimonialuser3} />
                    </a>
                    <div className="profile-user-info">
                      <h6>
                        <a>Judy Wells</a>
                      </h6>
                      <p>Chairman and founder</p>
                    </div>
                  </div>
                  <p>
                    My suits came back quickly and felt newer than the day I
                    bought them great that was easy to use,best part they came
                    to pick them up and drop them off for me.
                  </p>
                </div>
                <div className="testimonial-img">
                  <img src={testimonialimg3} alt="" />
                  <div className="testimonial-overlay">
                    <p>
                      My suits came back quickly and felt newer than the day I
                      bought them great that was easy to use,best part they came
                      to pick them up and drop them off for me.
                    </p>
                    {/* <a className='play-btn hover-btn'><i className="fas fa-caret-right"></i></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionEight;
