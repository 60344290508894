export const data = {
  home: {
    sections: [
      {
        _id: 1,
        is_section_enable: true,
        icon: "assets/img/section/section1.png",
      },
      {
        _id: 2,
        is_section_enable: true,
        icon: "assets/img/section/section2.png",
      },
      {
        _id: 3,
        is_section_enable: true,
        icon: "assets/img/section/section3.png",
      },
      {
        _id: 4,
        is_section_enable: true,
        icon: "assets/img/section/section4.png",
      },
      {
        _id: 5,
        is_section_enable: true,
        icon: "assets/img/section/section5.png",
      },
      {
        _id: 6,
        is_section_enable: true,
        icon: "assets/img/section/section6.png",
      },
      {
        _id: 7,
        is_section_enable: true,
        icon: "assets/img/section/section7.png",
      },
      {
        _id: 8,
        is_section_enable: true,
        icon: "assets/img/section/section8.png",
      },
      {
        _id: 9,
        is_section_enable: true,
        icon: "assets/img/section/section9.png",
      },
      {
        _id: 10,
        is_section_enable: true,
        icon: "assets/img/section/section10.png",
      },
    ],
    header: {
      primary_color: "000000",
      secondary_color: "ffcb00",
      nav: {
        nav_bg_color: "ffffff",
        menu_font_color: "2e3639",
        menu_font_color_active: "CC6600",
        menu_font_color_hover: "f07f1b",
        cart_section: {
          cart_bg_color: "ffffff",
          cart_icon_bg_color: "transparent",
          cart_value_color: "000000",
        },
        contact: {
          contact_is_enable: true,
          number: "+442086388690",
          contact_color: "2e3639",
        },
      },
      btn_group: {
        btn1: {
          text: "Login / Register",
          btn_bg_color: "ffffff",
          btn_color: "1b9db3",
          border_color: "cbcdcd",
          btn1_is_enable: true,
        },
      },
    },
    section_1: {
      banner: "assets/img/home_page_single_search.jpg",
      sub_heading_top: "24/7 Dry Cleaning & Laundry Service",
      title: "One Solution for all your Washing Needs",
      paragraph:
        "While you are doing something important, we will put things in order in the apartment, private house or office, clean the upholstered furniture and wash the windows.",
    },
    section_2: {
      title: "Select Your Time & Location For The Service",
      btn_group: {
        btn1: {
          text: "order now",
          placeholder: "Enter Zipcode",
          bg_color: "FFD700",
          color: "000000",
        },
      },
    },
    section_3: {
      is_section_enable: true,
      banner: "assets/img/trustimg.png",
      title: "Trust the Expert",
      paragraph:
        "Champion Cleaners aim has always been to provide 5 star premium dry cleaning and laundry services to expat and local populations of Dubai and the United Arab Emirates",
      sub_heading: "Unique solutions for all your laundry needs",
      check_list: [
        {
          _id: 1,
          title: "Clean for professional garment care",
        },
        {
          _id: 1,
          title: "Wash & Fold for items that don't need pressing",
        },
        {
          _id: 1,
          title: "Press Only for clean clothes that need pressing",
        },
        {
          _id: 1,
          title: "ShoeCare for cleaned and restored shoes",
        },
      ],
      btn_group: {
        btn1: {
          text: "More about Best at Laundry",
          bg_color: "#FFD700",
          color: "000000",
          is_enable: true,
        },
      },
    },
    section_4: {
      title: "Your Health & Safety Is Our Priority",
      cards: [
        {
          heading: "Contactless deliveries",
          icon: "assets/img/icons/priorityicon1.svg",
          image: "assets/img/home/priorityimg1.jpg",
          paragraph:
            "For our customers safety, we enabled the feature to allow customers to conduct contactless pick up & deliveries.",
        },
        {
          heading: "Monitoring the situation",
          icon: "assets/img/icons/priorityicon2.svg",
          image: "assets/img/home/priorityimg2.jpg",
          paragraph:
            "We’re keeping up to date with all developments from government to ensure the safety of our customers and staff.",
        },
        {
          heading: "Instant updates",
          icon: "assets/img/icons/priorityicon3.svg",
          image: "assets/img/home/priorityimg3.jpg",
          paragraph:
            "We’ll keep you posted throughout the process with texts/emails when driver is on their way and order has been cleaned.",
        },
      ],
    },
    section_5: {
      is_section_enable: true,
      banner: "assets/img/phone.png",
      title: "Just A Click Away",
      paragraph:
        "Our apps got a better and convenient way of getting your laundry and dry cleaning with our 5-star services. Download now and register to get more exclusive offers",
      check_list: [
        {
          _id: 1,
          icons: "assets/img/icons/secimg1.png",
          title: "Users",
          count: "25000",
        },
        {
          _id: 2,
          icons: "assets/img/icons/secimg2.png",
          title: "Downloads",
          count: "40000",
        },
        {
          _id: 3,
          icons: "assets/img/icons/secimg3.png",
          title: "Ratings",
          count: "4.5",
        },
      ],
      mobile_link_section: {
        is_enable: true,
        android_link:
          "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        ios_link:
          "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
      },
    },
    section_6: {
      heading: "Save with Our Subscriptions",
      card_1: {
        card_one_title: "Best at Laundry Bag",
        card_one_paragraph:
          "One bag = one price, fill it up and get it washed! Includes FREE next day delivery & all perks from Best at Laundry Go. Ideal for couples & families. Plans start from £25/month",
        card_one_image: "assets/img/home/wing1.png",
      },
      card_2: {
        card_two_title: "Ongoing discount on All Services",
        card_two_paragraph:
          "10% OFF all services (apart from laundry) for just £3.99/month. This includes Eco Dry Cleaning, shirt service & bed linen service. Ideal for single busy professionals & couples.",
        card_two_image: "assets/img/home/wing2.png",
      },
    },
    section_8: {
      title: "What Our Client Say",
      cards: [
        {
          profile: "assets/img/home/testimonialuser1.jpg",
          name: "Judy Wells",
          position: "Chairman and founder",
          message:
            "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          backgroundImage: "assets/img/home/testimonialimg1.jpg",
        },
        {
          profile: "assets/img/home/testimonialuser2.jpg",
          name: "Judy Wells two",
          position: "Chairman and founder",
          message:
            "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          backgroundImage: "assets/img/home/testimonialimg2.jpg",
        },
        {
          profile: "assets/img/home/testimonialuser3.jpg",
          name: "Judy Wells two",
          position: "Chairman and founder",
          message:
            "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          backgroundImage: "assets/img/home/testimonialimg3.jpg",
        },
        {
          profile: "assets/img/home/testimonialuser1.jpg",
          name: "Judy Wells",
          position: "Chairman and founder",
          message:
            "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          backgroundImage: "assets/img/home/testimonialimg1.jpg",
        },
        {
          profile: "assets/img/home/testimonialuser2.jpg",
          name: "Judy Wells two",
          position: "Chairman and founder",
          message:
            "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          backgroundImage: "assets/img/home/testimonialimg2.jpg",
        },
        {
          profile: "assets/img/home/testimonialuser3.jpg",
          name: "Judy Wells two",
          position: "Chairman and founder",
          message:
            "My suits came back quickly and felt newer than the day I bought them great that was easy to use,best part they came to pick them up and drop them off for me.",
          backgroundImage: "assets/img/home/testimonialimg3.jpg",
        },
      ],
    },
    section_10: {
      title: "Latest News & Events",
      button: {
        text: "View More",
        bg_color: "#ffcb00",
        color: "ffffff",
        is_enable: true,
      },
    },
    section_7: {
      is_section_enable: true,
      title: "On Demand Laundry, Dry Cleaning and Ironing Service",
    },
    section_9: {
      is_section_enable: true,
      title: "Frequently Asked Questions",
      paragraph:
        "online laundry & dry cleaning companies who simply just own the technology and farm the cleaning to other providers with little to no process system checks.",
      banner: [
        {
          banner: "assets/img/faqimg.jpg",
        },
      ],
      card_heading: "CALL US FOR A FREE QUOTE ON:",
      card_number: "+442086388690",
      card_buttontext: "Enquire Now",
      card_section: {
        collection: [
          {
            _id: 1,
            collection_title: "Do you have a re-cleaning Policy?",
            isEnabled: true,
            collection_paragraph:
              "Although we have quality control where we check each garment before we pack, if we are unable to remove stains we usually inform customers before. If something slips through and you are not completely satisfied with the quality of our dry cleaning, we will re-clean your item free of charge, as long as it’s within 24 hours.",
          },
          {
            _id: 2,
            collection_title: "Do you have policy grantees?",
            isEnabled: true,
            collection_paragraph: "Yes",
          },
        ],
      },
    },
    footer: {
      bg_color: "000",
      section_heading_color: "333333",
      section_paragraph_color: "c2c2c2",
      mobile_link_section: {
        is_enable: true,
        android_link:
          "https://play.google.com/store/apps/details?id=co.in.dreamguys.bestatlaundry",
        ios_link:
          "https://itunes.apple.com/us/app/best-laundry/id1434880614?ls=1&mt=8",
      },
      footer_section: {
        social_media: {
          facebook: {
            is_enable: true,
            facebook_link: "facebook.com",
          },
          instagram: {
            is_enable: true,
            instagram_link: "instagram.com",
          },
          whatsapp: {
            is_enable: true,
            whatsapp_link: "whatsapp.com",
          },
          linkedin: {
            is_enable: true,
            linkedin_link: "linkedin.com",
          },
          youtube: {
            is_enable: true,
            youtube_link: "youtube.com",
          },
          twitter: {
            is_enable: true,
            twitter_link: "twitter.com",
          },
        },
      },
    },
  },
};
