import React, { useEffect, useState } from "react";
import { cleancloths, trustimg } from "../../assets/img";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux/es/exports";

const SectionThree = () => {
  const navigate = useNavigate();
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);

  // HTML
  return <>
   <section className="trust-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="trust-content">
                <h2 className="section-title">
                {jsonData?.home?.section_3?.title}
                </h2>
                <p>
                {jsonData?.home?.section_3?.paragraph}
                </p>
                <h4>{jsonData?.home?.section_3?.sub_heading}</h4>
                <ul>
                  {jsonData?.home?.section_3?.check_list &&
                    Object.keys(jsonData.home.section_3.check_list).map(
                      (key) => (
                        <li key={key}>
                          {jsonData.home.section_3.check_list[key].title}
                        </li>
                      )
                    )}
                </ul>

                <div className="trust-content-btn">
                  <a
                    onClick={() => navigate("/aboutUs")}
                    className="btn primary-btn hover-btn"
                    style={{
                      background: `#${jsonData?.home?.section_3?.btn_group?.btn1?.bg_color}`,
                      color: `#${jsonData?.home?.section_3?.btn_group?.btn1?.color}`,
                    }}

                  >
                    {jsonData?.home?.section_3?.btn_group?.btn1?.text}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="trust-content-img">
                <img src={jsonData?.home?.section_3?.banner} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
  </>;
};

export default SectionThree;
