import React, { useEffect, useState } from "react";
import { fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";

const EditSectionSeven = () => {

    const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

    const handleChange = (e: any) => {
        const { value, name, type, checked } = e.target;
        setEditData((prevData:any) => ({
          ...prevData,
          home: {
            ...prevData.home,
            section_7: {
              ...prevData.home.section_7,
              [name]: value,
            },
          },
        }));
      };
    
      const saveSection4DataToFile = () => {
        dispatch(fetchHomeJsonList(editData));
        dispatch(saveThemeJsonData(editData));
      };

      useEffect(() => {
        setEditData(jsonData);
      }, [jsonData]);
      useEffect(() => {
        if (editData != undefined) {
          setEditData(editData);
        }
      }, [editData]);

    return(
        <>
        <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Seven Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 120 char)</small>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.home?.section_7?.title}
                      onChange={handleChange}
                      maxLength={120}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveSection4DataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default EditSectionSeven;