import React, { useEffect, useState } from "react";
import { cleancloths } from "../../assets/img";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";

const SectionSeven = () => {
  const [productList, setProductList] = useState<any | null>({});
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);

  useEffect(() => {
    const headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1`, {
        headers: headers,
      })
      .then((e) => {
        setProductList({ data: e.data });
        // e.data.data.category.slice(0, 1).map((category: any) =>
        //     setActive(category.main_category_name)
        // )
      });
  }, []);

  return (
    <>
      <section className="services-section" id="services-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title">
              {jsonData?.home?.section_7?.title}
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="services-slider">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                    1024: {
                      slidesPerView: 6,
                    },
                  }}
                >
                  {Object.keys(productList).length > 0 &&
                    productList.data.data.category.map((item: any) => {
                      return (
                        <SwiperSlide>
                          <div className="services-slider-grid">
                            <img src={item.web_banner_img} alt="" />
                            <h5>{item.main_category_name}</h5>
                            {/* <p>There are many variations of passages of Lorem Ipsum available.</p> */}
                            <a
                              href="#bookNow"
                              className="btn btnslider hover-btn"
                            >
                              Book Now<i className="fas fa-chevron-right"></i>
                            </a>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  {/* <SwiperSlide>  
                                    <div className='services-slider-grid'>
                                        <img src={dicon2} alt=""/>
                                        <h5>Dresses</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='services-slider-grid'>
                                        <img src={dicon3} alt=""/>
                                        <h5>Iron</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='services-slider-grid'>
                                        <img src={dicon4} alt=""/>
                                        <h5>Outdoor</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='services-slider-grid'>
                                        <img src={dicon5} alt=""/>
                                        <h5>Knitwear</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='services-slider-grid'>
                                        <img src={dicon6} alt=""/>
                                        <h5>Shoe Repair</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>                                
                                    <div className='services-slider-grid'>
                                        <img src={dicon1} alt=""/>
                                        <h5>Alterations</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='services-slider-grid'>
                                        <img src={dicon2} alt=""/>
                                        <h5>Dresses</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='services-slider-grid'>
                                        <img src={dicon3} alt=""/>
                                        <h5>Iron</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='services-slider-grid'>
                                        <img src={dicon4} alt=""/>
                                        <h5>Outdoor</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='services-slider-grid'>
                                        <img src={dicon5} alt=""/>
                                        <h5>Knitwear</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>  
                                    <div className='services-slider-grid'>
                                        <img src={dicon6} alt=""/>
                                        <h5>Shoe Repair</h5>
                                        <p>There are many variations of passages of Lorem Ipsum available.</p>
                                        <a href='#bookNow' className='btn btnslider hover-btn'>Book Now<i className="fas fa-chevron-right"></i></a>
                                    </div>
                                </SwiperSlide> */}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionSeven;
