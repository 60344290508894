import React from "react";
import { cleancloths,heroimg } from "../../assets/img";
import { useSelector } from "react-redux";

const SectionOne = () => {
  const jsonData: any = useSelector<any>((state) => state?.homeJsonList);
  // HTML
  return (
    <>
      <div
        className="home_single_search"
        style={{ background: `url(${jsonData?.home?.section_1?.banner})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6">
              <div className="banner-search-wrap">
                <span className="banner-tag">
                  <img src={cleancloths} alt="" />
                  {jsonData?.home?.section_1?.sub_heading_top}
                </span>
                <h1> {jsonData?.home?.section_1?.title} </h1>
                <p id="bookNow" className="d-none d-md-block">
                  {jsonData?.home?.section_1?.paragraph}
                </p>
              </div>
            </div>
            <div className="col-md-12 col-lg-6">
              <div className="heroimg d-md-block d-none">
                <img src={heroimg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionOne;
