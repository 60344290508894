import React, { useEffect, useState } from "react";
import {
  fetchCroppedImage,
  fetchHomeJsonList,
  saveThemeJsonData,
} from "../../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { ColorPicker } from "primereact/colorpicker";
import ImageCropper from "../ImageCropper/ImageCropper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

const EditSectionOne = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const images: Array<string> = [
    "assets/img/home_page_single_search.jpg",
    "assets/img/area-covered.jpg",
    "assets/img/area-bg.jpg",
    "assets/img/wash-fold-bg.jpg",
    "assets/img/home_page_single_search.jpg",
    "assets/img/area-covered.jpg",
    "assets/img/area-bg.jpg",
    "assets/img/wash-fold-bg.jpg",
    "assets/img/home_page_single_search.jpg",
    "assets/img/area-covered.jpg",
    "assets/img/area-bg.jpg",
    "assets/img/wash-fold-bg.jpg",
  ];

  // IMG
  const [cropImageHeight, setCropImageHeight] = useState("1920");
  const [cropImageWidth, setCropImageWidth] = useState("800");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
 
  const handleHeaderChange = (e: any) => {
    const { value, name } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      home: {
        ...prevJsonData.home,
        section_1: {
          ...prevJsonData.home.section_1,
          [name]: value,
        },
      },
    }));
  };
  const saveJsonData = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    setSelectedCropImage(image);
    setShowCropModal(true);
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink);
    }
  };
  const handleImageChange = (image: string) => {
    //Update the banner value in the JSON data
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      home: {
        ...prevJsonData.home,
        section_1: {
          ...prevJsonData.home.section_1,
          banner: image,
        },
      },
    }));
  };
  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);
  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section One Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="mb-3">
              <label className="form-label">
                Sub Title <small>(max 120 char)</small>
              </label>
              <input
                type="text"
                className="form-control"
                name="sub_heading_top"
                value={editData?.home?.section_1?.sub_heading_top}
                onChange={handleHeaderChange}
                maxLength={120}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Title<small>(max 120 char)</small>
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                onChange={handleHeaderChange}
                value={editData?.home?.section_1?.title}
                maxLength={120}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                name="paragraph"
                onChange={handleHeaderChange}
                value={editData?.home?.section_1?.paragraph}
                maxLength={1200}
              />
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="img-bg active">
                    <img
                      src={editData?.home?.section_1?.banner}
                      alt="Hero Image"
                    />
                  </div>
                  <div className="img-options">
                    <div className="row">
                      <div className="col-md-12">
                        <Swiper
                          spaceBetween={15}
                          navigation={true}
                          modules={[Navigation]}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 4,
                            },
                            1024: {
                              slidesPerView: 6,
                            },
                          }}
                          className="homejson-slider"
                        >
                          {images.map((images: string) => {
                            return (
                              <>
                                <SwiperSlide>
                                  <div
                                    className="img-option"
                                    onClick={() => handleImageChange(images)}
                                  >
                                    <img
                                      src={images}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              </>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Upload Image</label>
              <input
                type="file"
                accept="image/png, image/jpeg"
                className="form-control"
                name="image"
                onChange={updateImageSectionOne}
              />
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default EditSectionOne;
