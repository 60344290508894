import React, { useEffect } from "react";
import Header from "../header/header";
import Footer from "../footer";
import SectionOne from "../bookNow/section_one";
import SectionTwo from "../bookNow/section_two";
import SectionThree from "../bookNow/section_three";
import SectionFour from "../bookNow/section_four";
import SectionFive from "../bookNow/section_five";
import SectionSix from "../bookNow/section_six";
import SectionSeven from "../bookNow/section_seven";
import SectionEight from "../bookNow/section_eight";
import SectionNine from "../bookNow/section_nine";
import SectionTen from "../bookNow/section_ten";
import EditHeader from "./EditHeader/EditHeader";
import EditSectionTwo from "./edit-section-two/edit-section-two";
import EditSectionOne from "./edit-section-one/edit-section-one";
import EditSectionFour from "./edit-section-four/edit-section-four";
import EditSectionThree from "./edit-section-three/edit-section-three";
import EditSectionSix from "./edit-section-six/edit-section-six";
import EditSectionFive from "./edit-section-five/edit-section-five";
import EditSectionEight from "./edit-section-eight/edit-section-eight";
import EditSectionTen from "./edit-section-ten/edit-section-ten";
import Section from "./section/section";
import { useDispatch, useSelector } from "react-redux";
import EditSectionSeven from "./edit-section-seven/edit-section-seven";
import EditSectionNine from "./edit-section-nine/edit-section-nine";
import EditSectionFooter from "./edit-section-footer/edit-section-footer";
import { useNavigate } from "react-router-dom";
import { fetchThemeEdit } from "../../redux/Actions";

const EditTheme = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    if (token != null) {
      dispatch(fetchThemeEdit(token)).then((res: any) => {
        if (res == false) {
          navigate("/");
        }
      });
    } else {
      navigate("/");
    }
  }, []);

  // HTML
  return (
    <>
      <div className="edit-theme">
        <div className="section-editor">
          <a
            href="javascript:void(0);"
            data-bs-toggle="modal"
            data-bs-target="#headerEditModal"
            className="section-editor-icon"
          >
            <i className="fas fa-edit"></i>
          </a>
          <Header />
        </div>
        {jsonData?.home?.sections[0]?.is_section_enable === true && (
          <div className="section-editor">
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#sectionOneEditModal"
              className="section-editor-icon"
            >
              <i className="fas fa-edit"></i>
            </a>
            <SectionOne />
          </div>
        )}

        {jsonData?.home?.sections[1]?.is_section_enable === true && (
          <div className="section-editor">
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#sectionTwoEditModal"
              className="section-editor-icon"
            >
              <i className="fas fa-edit"></i>
            </a>
            <SectionTwo />
          </div>
        )}
        {jsonData?.home?.sections[2]?.is_section_enable === true && (
          <div className="section-editor">
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#sectionThreeEditModal"
              className="section-editor-icon"
            >
              <i className="fas fa-edit"></i>
            </a>
            <SectionThree />
          </div>
        )}

        {jsonData?.home?.sections[3]?.is_section_enable === true && (
          <div className="section-editor">
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#sectionFourEditModal"
              className="section-editor-icon"
            >
              <i className="fas fa-edit"></i>
            </a>
            <SectionFour />
          </div>
        )}

        {jsonData?.home?.sections[4]?.is_section_enable === true && (
          <div className="section-editor">
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#sectionFiveEditModal"
              className="section-editor-icon"
            >
              <i className="fas fa-edit"></i>
            </a>
            <SectionFive />
          </div>
        )}
        {jsonData?.home?.sections[5]?.is_section_enable === true && (
          <div className="section-editor">
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#sectionSixEditModal"
              className="section-editor-icon"
            >
              <i className="fas fa-edit"></i>
            </a>
            <SectionSix />
          </div>
        )}

        {jsonData?.home?.sections[6]?.is_section_enable === true && (
          <div className="section-editor">
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#sectionSevenEditModal"
              className="section-editor-icon"
            >
              <i className="fas fa-edit"></i>
            </a>
            <SectionSeven />
          </div>
        )}

        {jsonData?.home?.sections[7]?.is_section_enable === true && (
          <div className="section-editor">
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#sectionEightEditModal"
              className="section-editor-icon"
            >
              <i className="fas fa-edit"></i>
            </a>
            <SectionEight />
          </div>
        )}

        {jsonData?.home?.sections[8]?.is_section_enable === true && (
          <div className="section-editor">
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#sectionNineEditModal"
              className="section-editor-icon"
            >
              <i className="fas fa-edit"></i>
            </a>
            <SectionNine />
          </div>
        )}
        {jsonData?.home?.sections[9]?.is_section_enable === true && (
          <div className="section-editor">
            <a
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#sectionTenEditModal"
              className="section-editor-icon"
            >
              <i className="fas fa-edit"></i>
            </a>
            <SectionTen />
          </div>
        )}

        <div className="section-editor">
          <a
            href="javascript:void(0);"
            data-bs-toggle="modal"
            data-bs-target="#sectionFooterEditModal"
            className="section-editor-icon"
          >
            <i className="fas fa-edit"></i>
          </a>
          <Footer />
        </div>
      </div>

      <div
        className="modal fade"
        id="headerEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditHeader />
      </div>
      <div
        className="modal fade"
        id="sectionOneEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionOne />
      </div>
      <div
        className="modal fade"
        id="sectionTwoEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionTwo />
      </div>
      <div
        className="modal fade"
        id="sectionThreeEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionThree />
      </div>
      <div
        className="modal fade"
        id="sectionFourEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionFour />
      </div>
      <div
        className="modal fade"
        id="sectionFiveEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionFive />
      </div>
      <div
        className="modal fade"
        id="sectionSixEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionSix />
      </div>
      <div
        className="modal fade"
        id="sectionEightEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionEight />
      </div>
      <div
        className="modal fade"
        id="sectionTenEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionTen />
      </div>
      <div className="theme-changer-home">
        <a href="/">
          <i className="fas fa-sign-out-alt"></i>
        </a>
      </div>
      <div className="theme-changer">
        <a
          href="javascript:void(0);"
          data-bs-toggle="modal"
          data-bs-target="#themeColorEditmodal"
        >
          <i className="fas fa-cog"></i>
        </a>
      </div>
      <div
        className="modal fade"
        id="themeColorEditmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <Section />
      </div>
      <div
        className="modal fade"
        id="sectionSevenEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionSeven />
      </div>
      <div
        className="modal fade"
        id="sectionNineEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionNine />
      </div>
      <div
        className="modal fade"
        id="sectionFooterEditModal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <EditSectionFooter />
      </div>
    </>
  );
};

export default EditTheme;
