import React, { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";

const EditHeader = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const handleHeaderChange = (e: any) => {
    const { value, name, type, checked } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      home: {
        ...prevJsonData.home,
        header: {
          ...prevJsonData.home.header,
          [name]: value,
          nav: {
            ...prevJsonData.home.header.nav,
            [name]: value,
            cart_section: {
              ...prevJsonData.home.header.nav.cart_section,
              [name]: value,
              cart_label_section: {
                ...prevJsonData.home.header.nav.cart_section.cart_label_section,
                [name]: value,
              },
            },
            contact: {
              ...prevJsonData.home.header.nav.contact,
              [name]: value,
              contact_is_enable:
                type === "checkbox" && name === "contact_is_enable"
                  ? !prevJsonData.home.header.nav.contact.contact_is_enable
                  : prevJsonData.home.header.nav.contact.contact_is_enable,
            },
          },
          btn_group: {
            ...prevJsonData.home.header.btn_group,
            btn1: {
              ...prevJsonData.home.header.btn_group.btn1,
              [name]: value,
              btn1_is_enable:
                type === "checkbox" && name === "btn1_is_enable"
                  ? !prevJsonData.home.header.btn_group.btn1.btn1_is_enable
                  : prevJsonData.home.header.btn_group.btn1.btn1_is_enable,
            },
          },
        },
      },
    }));
  };

  const saveJsonData = () => {    
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(jsonData);    
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Header Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <h4 className="edit-section-hdr">Navigation Section </h4>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Nav Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="nav_bg_color"
                        value={editData?.home?.header?.nav?.nav_bg_color}
                        maxLength={120}
                      />
                      <ColorPicker
                        name="nav_bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={editData?.home?.header?.nav?.nav_bg_color}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Menu Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="menu_font_color"
                        value={editData?.home?.header?.nav?.menu_font_color}
                        maxLength={120}
                      />
                      <ColorPicker
                        name="menu_font_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={editData?.home?.header?.nav?.menu_font_color}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Menu hover</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="menu_font_color_hover"
                        value={
                          editData?.home?.header?.nav?.menu_font_color_hover
                        }
                        maxLength={120}
                      />
                      <ColorPicker
                        name="menu_font_color_hover"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.header?.nav?.menu_font_color_hover
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Menu Active</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="menu_font_color_active"
                        value={
                          editData?.home?.header?.nav?.menu_font_color_active
                        }
                        maxLength={120}
                      />
                      <ColorPicker
                        name="menu_font_color_active"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.header?.nav?.menu_font_color_active
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <h4 className="edit-section-hdr">Contact Section </h4>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Contact Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="number"
                      value={editData?.home?.header?.nav.contact?.number}
                      onChange={handleHeaderChange}
                      maxLength={120}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="contact_color"
                        value={
                          editData?.home?.header?.nav.contact?.contact_color
                        }
                        maxLength={120}
                      />
                      <ColorPicker
                        name="contact_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.header?.nav.contact?.contact_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="mb-3">
                    <label className="form-label">&nbsp;</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="contact_is_enable"
                        onClick={handleHeaderChange}
                        checked={
                          editData?.home?.header?.nav.contact?.contact_is_enable
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <h4 className="edit-section-hdr">Button Section </h4>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Button 1</label>
                    <input
                      type="text"
                      className="form-control"
                      name="text"
                      value={editData?.home?.header?.btn_group.btn1?.text}
                      onChange={handleHeaderChange}
                      maxLength={120}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Background</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="btn_bg_color"
                        value={
                          editData?.home?.header?.btn_group?.btn1?.btn_bg_color
                        }
                        maxLength={120}
                      />
                      <ColorPicker
                        name="btn_bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.header?.btn_group?.btn1?.btn_bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="btn_color"
                        value={
                          editData?.home?.header?.btn_group.btn1?.btn_color
                        }
                        maxLength={120}
                      />
                      <ColorPicker
                        name="btn_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.header?.btn_group?.btn_group?.btn1
                            ?.btn_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Border</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="border_color"
                        value={
                          editData?.home?.header?.btn_group.btn1?.border_color
                        }
                        maxLength={120}
                      />
                      <ColorPicker
                        name="border_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.header?.btn_group?.btn1?.border_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="mb-3">
                    <label className="form-label">&nbsp;</label>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="btn1_is_enable"
                        onClick={handleHeaderChange}
                        checked={
                          editData?.home?.header?.btn_group?.btn1.btn1_is_enable
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <h4 className="edit-section-hdr">Nav Menu Cart Section </h4>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Cart BG</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="cart_bg_color"
                        value={
                          editData?.home?.header?.nav?.cart_section
                            ?.cart_bg_color
                        }
                        maxLength={120}
                      />
                      <ColorPicker
                        name="cart_bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.header?.nav?.cart_section
                            ?.cart_bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Cart icon BG</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="cart_icon_bg_color"
                        value={
                          editData?.home?.header?.nav?.cart_section
                            ?.cart_icon_bg_color
                        }
                        maxLength={120}
                      />
                      <ColorPicker
                        name="cart_icon_bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.header?.nav?.cart_section
                            ?.cart_icon_bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Cart value Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="cart_value_color"
                        value={
                          editData?.home?.header?.nav?.cart_section
                            ?.cart_value_color
                        }
                        maxLength={120}
                      />
                      <ColorPicker
                        name="cart_value_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.header?.nav?.cart_section
                            ?.cart_value_color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHeader;
