import { Component } from "react";
import { useSelector } from "react-redux";



function Whatsapp() {

  const settingsData: any = useSelector<any>(
    (state) => state?.settings
  );



const link = settingsData?.WebsiteSettings?.social_link10
    return (

      <div className="link-whatsapp">
        {link !== '' ? 
        <a target="blank" href={link}>
          <i className="fab fa-whatsapp"></i>
        </a>
        :
        <div></div>
    }
      </div>
    );
  }

export default Whatsapp;
