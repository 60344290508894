import React, { useEffect, useState } from "react";
import { fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";
import { useDispatch, useSelector } from "react-redux";
import { ColorPicker } from "primereact/colorpicker";

const EditSectionTwo = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const handleHeaderChange = (e: any) => {
    const { value, name } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      home: {
        ...prevJsonData.home,
        section_2: {
          ...prevJsonData.home.section_2,
          [name]: value,
          btn_group: {
            ...prevJsonData.home.section_2.btn_group,
            btn1: {
              ...prevJsonData.home.section_2.btn_group.btn1,
              [name]: value,
            },
          },
        },
      },
    }));
  };
  const saveJsonData = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);
  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);
  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Two Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Section Title</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={editData?.home?.section_2?.title}
                        maxLength={120}
                        onChange={handleHeaderChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Button 1</label>
                    <input
                      type="text"
                      className="form-control"
                      name="text"
                      value={editData?.home?.section_2?.btn_group?.btn1?.text}
                      onChange={handleHeaderChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Bg Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="bg_color"
                        value={
                          editData?.home?.section_2?.btn_group?.btn1?.bg_color
                        }
                      />
                      <ColorPicker
                        name="bg_color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.section_2?.btn_group?.btn1?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className=" mb-3">
                    <label className="form-label">Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="color"
                        value={
                          editData?.home?.section_2?.btn_group?.btn1?.color
                        }
                      />
                      <ColorPicker
                        name="color"
                        format="hex"
                        onChange={handleHeaderChange}
                        value={
                          editData?.home?.section_2?.btn_group?.btn1?.color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Placeholder</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="placeholder"
                        value={editData?.home?.section_2?.btn_group?.btn1?.placeholder}
                        maxLength={120}
                        onChange={handleHeaderChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSectionTwo;
