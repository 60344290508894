import React from "react";

import SectionOne from "./section_one";
import SectionTwo from "./section_two";
import SectionThree from "./section_three";
import SectionFour from "./section_four";
import SectionFive from "./section_five";
import SectionSix from "./section_six";
import SectionSeven from "./section_seven";
import SectionEight from "./section_eight";
import SectionNine from "./section_nine";

function BookNow() {
  return (
    <>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      <SectionSeven />
      <SectionEight />
      <SectionNine />
    </>
  );
}

export default BookNow;
