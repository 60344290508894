import React, { useEffect, useMemo, useState } from "react";
import { loader } from "../../assets/img";
import axios from "axios";
import { useSelector } from "react-redux";
const Loader = () => {
  const [isLoading, setLoading] = useState(false);
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const state: any = useSelector((state) => state);
  const useMemoFunction = useMemo(() => {
    axios.interceptors.request.use((request) => {
      let splitRequestUrl = request.url?.split("?") || "";
      let requestUrl = splitRequestUrl[0];
      let currentUrl = window.location.pathname;
      let arr = currentUrl.split("/");
      let splitUrl = arr[1];

      // if(currentUrl === "/productLists" || currentUrl === "/areaNotCovered" || splitUrl === "editAddress" || currentUrl === "/newAddress" || localStorage.getItem("disableLoader")){
      //   setLoading(false)
      // }else {setLoading(false)}
      // if(requestUrl === `${base_url}/api/get_postal_codes`){
      //   setLoading(false)
      // }
      if (
        currentUrl === "/checkout" ||
        requestUrl === `${base_url}/api/submit_order` ||
        requestUrl === `${base_url}/api/settings` ||
        currentUrl.includes("my") ||
        currentUrl.includes("orderView") ||
        currentUrl === "/pricing" ||
        currentUrl === "/areaCovered" ||
        currentUrl === "/faq" ||
        currentUrl.includes("dry") ||
        currentUrl.includes("shopHome") ||
        currentUrl === "/aboutUs" ||
        currentUrl === "/reviews" ||
        currentUrl === "/termsAndConditions" ||
        currentUrl === "/privacyPolicy" ||
        currentUrl === "/cookiePolicy"
      ) {
        if (
          requestUrl != `${base_url}/api/get_postal_codes` &&
          requestUrl != `${base_url}/api/create_setup_intent` &&
          requestUrl != `${base_url}/api/list_offer_details` &&
          requestUrl != `${base_url}/api/user_notification_list` &&
          requestUrl != `${base_url}/api/get_categories?group_id=1` &&
          requestUrl.includes("get_delivery_timeslots") != true
        ) {
          setLoading(true);
        }
      }
      const btn = document.getElementsByTagName("button");
      const abtn = document.getElementsByTagName("a");
      for (let i = 0; i < btn.length; i++) {
        const b = btn[i];
        b.classList.add("disabled");
      }
      for (let i = 0; i < abtn.length; i++) {
        const a = abtn[i];
        a.classList.add("disabled");
      }
      return request;
    });
    axios.interceptors.response.use(
      (response) => {
        let apiUrl = response.request.responseURL;
        let currentUrl = window.location.pathname;
        let splitRequestUrl = response.request.url?.split("?") || "";
        let requestUrl = splitRequestUrl[0];

        const btn = document.getElementsByTagName("button");
        const abtn = document.getElementsByTagName("a");
        if (
          currentUrl === "/checkout" &&
          (localStorage.getItem("isCheckout") != null ||
            localStorage.getItem("completeorderlogin") != null)
        ) {
          if (apiUrl === `${base_url}/api/submit_order`) {
            setTimeout(() => {
              setLoading(false);
            }, 5000);
          }
          if (
            (apiUrl.includes(`get_pickup_timeslots_delivery_dates`) ||
              apiUrl.includes(`get_delivery_timeslots`)) &&
            document.getElementById("step2") != null &&
            document.getElementById("step1") == null
          ) {
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          } else {
            setTimeout(() => {
              setLoading(false);
            }, 15000);
          }

          for (let i = 0; i < btn.length; i++) {
            const b = btn[i];
            b.classList.remove("disabled");
          }
          for (let i = 0; i < abtn.length; i++) {
            const a = abtn[i];
            a.classList.remove("disabled");
          }
          return response;
        } else if (currentUrl === "/checkout") {
          if (apiUrl === `${base_url}/api/submit_order`) {
            setTimeout(() => {
              setLoading(false);
            }, 5000);
          }
          if (apiUrl === `${base_url}/api/save_address`) {
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          } else {
            setTimeout(() => {
              setLoading(false);
            }, 10000);
          }

          for (let i = 0; i < btn.length; i++) {
            const b = btn[i];
            b.classList.remove("disabled");
          }
          for (let i = 0; i < abtn.length; i++) {
            const a = abtn[i];
            a.classList.remove("disabled");
          }
          return response;
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          for (let i = 0; i < btn.length; i++) {
            const b = btn[i];
            b.classList.remove("disabled");
          }
          for (let i = 0; i < abtn.length; i++) {
            const a = abtn[i];
            a.classList.remove("disabled");
          }
          return response;
        }
      },
      (error) => {
        const btn = document.getElementsByTagName("button");
        const abtn = document.getElementsByTagName("a");
        for (let i = 0; i < btn.length; i++) {
          const b = btn[i];
          b.classList.remove("disabled");
        }
        for (let i = 0; i < abtn.length; i++) {
          const a = abtn[i];
          a.classList.remove("disabled");
        }
        setLoading(false);
      }
    );
  }, [isLoading]);

  return (
    <div>
      {isLoading! && (
        <div className="loading">
          <img src={loader} className="img-fluid" />
        </div>
      )}
    </div>
  );
};

export default Loader;
